angular.module('roleService', []).factory('Role', function($http) {

    return {

        togglePermission: function(roleId, permissionId) {
            return $http({
                method: 'POST',
                url: 'admin/roles/' + roleId + '/permission',
                offline: true,
                data: { id: permissionId }
            });
        },

        destroy: function(roleId) {
            return $http({
                method: 'POST',
                url: 'admin/roles/' + roleId,
                offline: true,
                data: {_method: 'DELETE'},
            });
        },

      	update: function(roleId) {
            return $http({
                method: 'POST',
                url: 'admin/roles/' + roleId,
                offline: true,
                data: {_method: 'PATCH'},
            });
        },  
    };
});
