angular.module('passwordController', []).controller('passwordController', function($scope) {

    switch (window.location.hash.charAt(1)) {
        case '1':
            $scope.displayChoice = false;
            $scope.displayCase1 = true;
            break;
        case '2':
            $scope.displayChoice = false;
            $scope.displayCase2 = true;
            break;
        case '3':
            $scope.displayChoice = false;
            $scope.displayCase3 = true;
            break;
        default:
            $scope.displayChoice = true;
            $scope.displayCase1 = false;
            $scope.displayCase2 = false;
            $scope.displayCase3 = false;
    }

    $scope.displayCase = function() {
        $scope.displayChoice = false;
        $scope['displayCase' + $scope.case] = true;
        window.location.hash = $scope.case;
    };
});
